import React, { Component } from 'react';
import styled from '@emotion/styled';
import { SURFACE_COLORS } from 'Style/colors';
import { UI_TEXT_TYPES } from 'Style/typography';
import { styleOnlyProps } from 'Style/style-helpers';

// Utils
import { getReadableNumber } from 'Utils/numbers-formatter';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import SectionUtil from 'Utils/content/section-util';

// Components
import WebLink from './web-link';
import withT from 'ComponentLibrary/hocs/withT';

const ListItem = styled('li', styleOnlyProps('isSelected'))<{
  isSelected?: boolean;
}>(
  {
    textAlign: 'left',
  },
  ({ isSelected }) => {
    if (isSelected) {
      return { backgroundColor: SURFACE_COLORS.secondary };
    }
  },
);
const SupportingText = styled.p(UI_TEXT_TYPES.SUPPORTING);

type RecommendedSectionListItemProps = {
  section: Flipboard.EditorialBoard;
  isSelected?: boolean | false;
  displayFollowers?: boolean | false;
  onClick: (e: React.MouseEvent, section: Flipboard.EditorialBoard) => void;
  t: Flipboard.TFunction;
};

class RecommendedSectionListItem extends Component<RecommendedSectionListItemProps> {
  constructor(props: RecommendedSectionListItemProps) {
    super(props);

    this.itemEl = React.createRef();
  }

  componentDidUpdate(prevProps: RecommendedSectionListItemProps) {
    if (this.props.isSelected && !prevProps.isSelected) {
      this.itemEl?.current?.scrollIntoView({ block: 'nearest' });
    }
  }
  itemEl: React.RefObject<HTMLLIElement>;

  handleClick = (e: React.MouseEvent) => {
    this.props.onClick(e, this.props.section);
  };

  render() {
    const { section, isSelected, displayFollowers, t } = this.props;

    const path = SectionUtil.projection(section).canonicalPath;
    return (
      <ListItem isSelected={isSelected} ref={this.itemEl}>
        <WebLink
          className="topic-list__item-target ui-text--title"
          href={path}
          onClick={this.handleClick}
          navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
        >
          {section.title}
          {displayFollowers && section.followers && (
            <SupportingText>
              {getReadableNumber(section.followers)}&nbsp;
              {t('reading_about_this')}
            </SupportingText>
          )}
        </WebLink>
      </ListItem>
    );
  }
}

export default withT(RecommendedSectionListItem);
