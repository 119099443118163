import React, { Component } from 'react';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'Style/breakpoints';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';
import { LAYOUT_SIZES } from 'Style/layout';
import { styleObject } from 'Style/type-helpers';

// Utils
import WindowKeyDown, { KEYS } from 'Utils/window-key-down';

// Components
import RecommendedSectionListItem from './recommended-section-list-item';

const StyledRecommendedSectionList = styled.ul({
  maxWidth: LAYOUT_SIZES.MAIN_COLUMN_WIDTH,
  margin: '0 auto',
});
const GroupHeader = styled.h2(UI_TEXT_TYPES.SUPPORTING_EMPHASIS);

const RecommendedSectionListWrapper = styled.nav(
  {
    paddingBottom: SPACING.BASE8X,
  },
  styleObject(
    BREAKPOINTS.tabletPortraitUp({
      paddingBottom: '0px',
    }),
  ),
);
type RecommendedSectionListProps = {
  sectionGroups: Array<{
    title: string;
    sections: Array<Flipboard.EditorialBoard>;
  }>;
  displayFollowers?: boolean | false;
  onSelect: (
    e: React.MouseEvent | React.KeyboardEvent,
    section: Flipboard.EditorialBoard,
  ) => void;
};

type RecommendedSectionListState = {
  selectedGlobalIndex: number | null;
};
class RecommendedSectionList extends Component<
  RecommendedSectionListProps,
  RecommendedSectionListState
> {
  constructor(props: RecommendedSectionListProps) {
    super(props);

    this.state = { selectedGlobalIndex: null };
    this.windowKeyDown = new WindowKeyDown(this.handleKeyDown);
  }

  componentDidMount() {
    this.windowKeyDown.subscribe();
  }

  componentWillUnmount() {
    this.windowKeyDown.unsubscribe();
  }
  windowKeyDown: WindowKeyDown;

  handleKeyDown = (e: React.KeyboardEvent) => {
    const { sectionGroups } = this.props;
    const sections = sectionGroups
      .map((sectionGroup) => sectionGroup.sections)
      // flatten
      .reduce(
        (acc, val) => acc.concat(val),
        [] as Array<Flipboard.EditorialBoard>,
      );
    if (!sections || sections.length === 0) {
      return;
    }
    const { selectedGlobalIndex } = this.state;
    switch (e.keyCode) {
      case KEYS.UP: {
        let newIndex: number;
        if (selectedGlobalIndex === null || selectedGlobalIndex - 1 < 0) {
          newIndex = sections.length - 1;
        } else {
          newIndex = selectedGlobalIndex - 1;
        }
        this.setState({ selectedGlobalIndex: newIndex });
        break;
      }
      case KEYS.DOWN: {
        let newIndex: number;
        if (
          selectedGlobalIndex === null ||
          selectedGlobalIndex + 1 >= sections.length
        ) {
          newIndex = 0;
        } else {
          newIndex = selectedGlobalIndex + 1;
        }
        this.setState({ selectedGlobalIndex: newIndex });
        break;
      }
      case KEYS.ENTER: {
        if (selectedGlobalIndex !== null) {
          const section = sections[selectedGlobalIndex];
          this.props.onSelect(e, section);
        }
      }
    }
  };

  render() {
    const { sectionGroups, displayFollowers, onSelect } = this.props;
    const { selectedGlobalIndex } = this.state;
    let globalIndex = -1;
    return sectionGroups.map((group) => {
      if (group.sections.length === 0) {
        return null;
      }
      return [
        group.title ? (
          <GroupHeader key={`${group.title}-title`}>{group.title}</GroupHeader>
        ) : null,
        <RecommendedSectionListWrapper key={`${group.title}-topics`}>
          <StyledRecommendedSectionList>
            {group.sections.map((section) => {
              globalIndex = globalIndex + 1;
              return (
                <RecommendedSectionListItem
                  section={section}
                  isSelected={selectedGlobalIndex === globalIndex}
                  displayFollowers={displayFollowers}
                  key={section.remoteid}
                  onClick={onSelect}
                />
              );
            })}
          </StyledRecommendedSectionList>
        </RecommendedSectionListWrapper>,
      ];
    });
  }
}

export default RecommendedSectionList;
